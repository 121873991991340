const messages = {
    "campaigns": "Campañas",
    "campaignsSubtitle": "Gestiona tus campañas de WhatsApp",
    "youCanOnlyHaveUpToXCampaigns": "Solo puedes tener hasta {x} campañas",
    "youWillNeedToDeleteSomeCampaignsToCreateANewOne": "Necesitarás eliminar algunas campañas para crear una nueva",
    "createCampaign": "Crear Campaña",
    "editCampaign": "Editar Campaña",
    "campaignsDeleteDialogDescription": "¿Estás seguro de que quieres eliminar esta campaña?",
    "campaignsDialogConfirmButton": "Confirmar",
    "campaignsDeleteDialogTitle": "Eliminar campaña {campaign_name}",
    "campaignDeletingError": "Ocurrió un error al eliminar la campaña",
    "name": "Nombre",
    "dateAndTime": "Fecha y Hora",
    "totalMessages": "Total de Mensajes",
    "template": "Plantilla",
    "status": "Estado",
    "actions": "Acciones",
    "campaignName": "Nombre de la Campaña",
    "selectTemplate": "Seleccionar Mensaje de inicio",
    "skipQualityWarning": "Omitir Advertencia de Calidad",
    "antiSpam": "Anti-Spam",
    "updateCampaign": "Actualizar Campaña",
    "cancel": "Cancelar",
    "csvFileUpload": "Subir archivo CSV",
    "csvFileError": "El archivo CSV no contiene las columnas requeridas",
    "campaignCreatedSuccess": "Campaña creada exitosamente",
    "campaignUpdatedSuccess": "Campaña actualizada exitosamente",
    "scheduled": "Programada",
    "draft": "Borrador",
    "completed": "Completada",
    "in_progress": "En Progreso",
    "adminPhone": "Teléfono del Administrador",
    "adminName": "Nombre del Administrador",
    "timezone": "Zona Horaria",
   "csvFile": "Archivo CSV",
   "next": "Siguiente",
    "back": "Atrás",
    "Campaign Details": "Detalles de la Campaña",
    "File Upload and Template": "Carga de Archivo y Mensaje de inicio",
    "Additional Settings": "Configuraciones Adicionales",
    "messageWarningCsv":"Por favor, cargue un archivo CSV con las columnas: firstname, lastname, phone",
    "selectTemplateForYourCampaign":"Seleccione el Mensaje de inicio para su campaña",
    "contentOfTheSelectedTemplate": "Content of the selected template",
    "downloadSampleCSVFile": "Descargar archivo CSV de ejemplo",
    "CSVFileExample":"Ejemplo de archivo CSV",
    "close": "Cerrar",
    "NoCSVFileSelected":"No se ha seleccionado un archivo CSV",
    "pleaseSelectACSVFileToContinue":"Por favor, seleccione un archivo CSV para continuar",
    "theStartMessageIsRequired": "El mensaje de inicio es requerido",
    "enterTheTotalNumberOfMessagesForThisCampaign":"Ingrese el número total de mensajes para esta campaña",
    "labelAntiSpam":"No se enviara a los clientes que recibieron este mismo mensaje de inicio en los ultimos N dias atras",
    "enterAdminPhone":"Ingrese el número de teléfono del administrador",
    "enterAdminName":"Ingrese el nombre del administrador",
    "SkipQualityWarning":"Omitir advertencia de calidad",
    "campaignConfirm":"Confirmacion de campaña",
    "adminName": "El nombre del administrador es requerido",
    "adminPhone": "El teléfono del administrador es requerido",
    "totalMessages": "El total de mensajes es requerido",
    "errorAdminName": "Error: El nombre del administrador es requerido",
    "dialogAdminName": "Por favor, ingrese el nombre del administrador para continuar",
    "errorAdminPhone": "Error: El teléfono del administrador es requerido",
    "dialogAdminPhone": "Por favor, ingrese el teléfono del administrador para continuar",
    "errorTotalMessages": "Error: El total de mensajes es requerido",
    "dialogTotalMessages": "Por favor, ingrese el total de mensajes para continuar"
}
export default messages